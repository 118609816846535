/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import { bool, object, any } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
// import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Carousel,
  Breadcrumb,
  Card,
  Container,
  CardGroup,
  // Form,
  // FloatingLabel,
  // Button,
} from 'react-bootstrap';
import ReactPlayer from 'react-player';
// import CurrencyFormat from 'react-currency-format';
import Loading from '../loading/loading';
import * as reduxActions from '../../redux/actions';
import FixedContainer from './fixedContainer';
import { numFormat } from '../../helpers/input/format';
import userdefult from '../../assets/image/lobbie_logo.svg';
import './property.scss';

const getViewMessage = (message, onHandlerReturn, phone) => (
  <>
    <Card.Body>{`Muchas gracias! ${message}`}</Card.Body>
    <div>
      <button
        style={{ width: 115, marginBottom: 20, borderRadius: 3 }}
        type="button"
      >
        {phone}
      </button>
    </div>
    <div>
      {' '}
      <button
        style={{
          backgroundColor: '#59ACF9',
          width: 60,
          height: 45,
          marginBottom: 20,
          borderRadius: 3,
        }}
        type="button"
        onClick={onHandlerReturn}
      >
        Atrás
      </button>
    </div>
  </>
);

const Property = ({ user, propertyDetail, actions, generalLoading }) => {
  document.body.style = 'background: white;';
  const { inView: showContainer } = useInView();
  const location = useLocation();
  const { propertyId } = useParams();

  const [property, setProperty] = useState({});
  const [userState, setUserState] = useState(user);
  // const [validated, setValidated] = useState(false);
  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [phone, setPhone] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const userId = window.location.pathname.split('/');
  const onHandlerReturn = () => setShowForm(true);

  useEffect(() => {
    if (user) {
      setUserState(user);
    }
  }, [user]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate(`/${userId[1]}/`, {
        state: {
          propertyId,
          pageNumber: location?.state?.pageNumber || 0,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (propertyId) {
      actions.getPropertyByid(propertyId);
    }
  }, []);

  useEffect(() => {
    if (propertyDetail) {
      setProperty(propertyDetail.property);
    }
  }, [propertyDetail]);

  // const onHandlerBeContact = () => {
  //   if (!name && !email && !phone) {
  //     setValidated(true);
  //     return;
  //   }
  //   setMessage('Te estaremos contactando en lo mas breve posible');
  //   setShowForm(false);
  // };

  const onHandlerClikPhone = () => {
    setShowForm(false);
    setMessage('Quedo atento a tu llamada');
    window.location.replace(`tel:${property.user.phone}`);
  };

  const onHandlerClikWhatsApp = () => {
    const phone = property.user.phone.replace('+', '');
    setShowForm(false);
    setMessage('Espero tu mensaje de WhatsApp');
    if (window.screen.availWidth <= 768) {
      window.open(
        `https://api.whatsapp.com/send/?phone=${phone}&text=Hola%2Ctengo algunas preguntas sobre el ${
          property.type
        } en ${property.neighborhood_name} de ${numFormat(property.price)}. ${
          window.location.href
        }&app_absent=0`
      );
    } else {
      window.open(
        `https://web.whatsapp.com/send/?phone=${phone}&text=Hola%2Ctengo algunas preguntas sobre el ${
          property.type
        } en ${property.neighborhood_name} de ${numFormat(property.price)}. ${
          window.location.href
        }&app_absent=0`
      );
    }
  };
  if (window.screen.availWidth <= 768) {
    return generalLoading ? (
      <Loading />
    ) : (
      <div style={{ overflowX: 'hidden' }}>
        <Row key={property.id}>
          <Col lg={8}>
            <Container fluid>
              <Row>
                <Col>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      onClick={() =>
                        navigate(`/${userId[1]}/`, {
                          state: {
                            propertyId: property.id,
                            pageNumber: location?.state?.pageNumber || 0,
                          },
                        })
                      }
                    >
                      Lista
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                      Inmueble {`${property.id}`}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>

            <Carousel interval={null}>
              {property.photos
                ? property.photos.map(({ id, file: { url } }) => (
                    <Carousel.Item key={id}>
                      <img
                        className="img-carousel"
                        src={`${url}`}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  ))
                : ''}
            </Carousel>

            <Container>
              <Row style={{ marginLeft: -3, marginRight: -3 }}>
                <Card style={{ marginTop: 15 }}>
                  <Card.Body>Código: {`${property.code}`}</Card.Body>
                </Card>
                <Card
                  style={{
                    fontWeight: 'bold',
                    marginTop: 15,
                  }}
                >
                  <Card.Body>
                    {`${property.type}`} en {`${property.neighborhood_name}`},
                    {` ${property.city}`}
                  </Card.Body>
                </Card>

                <Card
                  style={{
                    height: 80,
                    border: 0,
                    marginTop: 15,
                  }}
                >
                  <div
                    style={{
                      marginTop: 25,
                      justifyContent: 'space-around',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <h5 style={{ fontWeight: 'bold' }}>Precio</h5>
                    <h5>$ {`${numFormat(property.price)}`}</h5>
                  </div>
                </Card>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Card style={{ marginTop: 15 }}>
                      <Card.Body>
                        <Card.Title
                          style={{ marginBottom: 10, fontWeight: 'bold' }}
                        >
                          Datos principales
                        </Card.Title>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Área: {`${property.area}`} m2
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Habitaciones: {`${property.bedrooms}`}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Baños: {`${property.bathrooms}`}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Parqueaderos: {`${property.parking}`}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Valor Administración: $
                          {`${numFormat(property.management_fees)}`}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Estrato: {`${property.strata}`}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Año Construido:{' '}
                          {`${property.year ? property.year : 'Sin definir'}`}
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>

                    <Card style={{ height: 273, marginTop: 15 }}>
                      <Card.Body>
                        <Card.Title style={{ fontWeight: 'bold' }}>
                          Características
                        </Card.Title>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Cocina Abierta:{' '}
                          {property.builtin_kitchen ? 'Si' : 'No'}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Ascensor: {property.elevator ? 'Si' : 'No'}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Balcón: {property.balcony ? 'Si' : 'No'}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Cuarto de Servicio:{' '}
                          {property.service_room ? 'Si' : 'No'}
                        </Card.Subtitle>
                        <Card.Subtitle
                          style={{ marginBottom: 10, marginTop: 10 }}
                        >
                          Piscina: {property.swimming_pool ? 'Si' : 'No'}
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 15, marginLeft: -3, marginRight: -3 }}>
                <Card>
                  <Card.Body>
                    <Card.Title style={{ fontWeight: 'bold' }}>
                      Descripción
                    </Card.Title>
                    {`${property.description}`}
                  </Card.Body>
                </Card>
              </Row>
            </Container>
          </Col>
          <Col>
            <Container>
              <Card className="contact">
                <div className="header-contact">
                  <span>Contacta al Agente encargado</span>
                  <img
                    className="img-circule"
                    src={`${
                      userState && userState.profile_photo?.url
                        ? userState.profile_photo.url
                        : userdefult
                    }`}
                  />
                </div>
                <div className="body-contact">
                  <span>
                    {userState && userState.company_name ? (
                      <p>{`${
                        userState && userState.company_name
                          ? userState.company_name
                          : ''
                      }`}</p>
                    ) : (
                      <p>{`${
                        userState && userState.first_name
                          ? userState.first_name
                          : ''
                      } ${
                        userState && userState.last_name
                          ? userState.last_name
                          : ''
                      }`}</p>
                    )}
                  </span>
                  <div>
                    {!showForm &&
                      getViewMessage(
                        message,
                        onHandlerReturn,
                        property.user.phone
                      )}
                  </div>
                </div>
                <div className="footer-contact">
                  <button type="button" onClick={onHandlerClikPhone}>
                    Llamar
                  </button>
                </div>
                <div className="footer-contact">
                  <button type="button" onClick={onHandlerClikWhatsApp}>
                    Contactar por Whatsapp
                  </button>
                </div>
                <div>
                  {userState && userState.hubspot_id !== '' && (
                    <ReactPlayer
                      url={`https://www.youtube.com/watch?v=${userState.hubspot_id}`}
                      className="react-player"
                      width="100%"
                      height="300px"
                    />
                  )}
                </div>
              </Card>
            </Container>
          </Col>
        </Row>
        {!showContainer && (
          <FixedContainer
            onHandlerClikPhone={onHandlerClikPhone}
            onHandlerClikWhatsApp={onHandlerClikWhatsApp}
          />
        )}
      </div>
    );
  }
  return generalLoading ? (
    <Loading />
  ) : (
    <Container fluid>
      <Row key={property.id}>
        <Col lg={8}>
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() =>
                    navigate(`/${userId[1]}/`, {
                      state: {
                        propertyId: property.id,
                        pageNumber: location?.state?.pageNumber || 0,
                      },
                    })
                  }
                >
                  Lista
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Inmueble {`${property.id}`}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ marginBottom: 10, fontWeight: 'bold' }}>
                <Card.Body>
                  {`${property.type}`} en {`${property.neighborhood_name}`},
                  {` ${property.city}`}
                </Card.Body>
              </Card>
              <Card className="container-carousel">
                <Carousel interval={null} className="responive-slider-img">
                  {property.photos
                    ? property.photos.map(({ id, file: { url } }) => (
                        <Carousel.Item key={id}>
                          <div className="context-img">
                            <img
                              className="d-block w-100"
                              src={`${url}`}
                              alt="First slide"
                            />
                          </div>
                        </Carousel.Item>
                      ))
                    : ''}
                </Carousel>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <CardGroup>
              <Card
                style={{
                  height: 80,
                  border: 0,
                }}
              >
                <div
                  style={{
                    marginTop: 25,
                    justifyContent: 'space-around',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <h5 style={{ fontWeight: 'bold' }}>Precio</h5>
                  <h5>$ {`${numFormat(property.price)}`}</h5>
                </div>
              </Card>
            </CardGroup>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Row>
                <Col lg={6}>
                  <Card>
                    <Card.Body>
                      <Card.Title
                        style={{ marginBottom: 10, fontWeight: 'bold' }}
                      >
                        Datos principales
                      </Card.Title>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Área: {`${property.area}`} m2
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Habitaciones: {`${property.bedrooms}`}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Baños: {`${property.bathrooms}`}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Parqueaderos: {`${property.parking}`}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Valor Administración: $
                        {`${numFormat(property.management_fees)}`}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Estrato: {`${property.strata}`}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Año Construido:{' '}
                        {`${property.year ? property.year : 'Sin definir'}`}
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card style={{ height: 273 }}>
                    <Card.Body>
                      <Card.Title style={{ fontWeight: 'bold' }}>
                        Características
                      </Card.Title>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Cocina Abierta: {property.builtin_kitchen ? 'Si' : 'No'}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Ascensor: {property.elevator ? 'Si' : 'No'}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Balcón: {property.balcony ? 'Si' : 'No'}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Cuarto de Servicio:{' '}
                        {property.service_room ? 'Si' : 'No'}
                      </Card.Subtitle>
                      <Card.Subtitle
                        style={{ marginBottom: 10, marginTop: 10 }}
                      >
                        Piscina: {property.swimming_pool ? 'Si' : 'No'}
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 30 }}>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title style={{ fontWeight: 'bold' }}>
                    Descripción
                  </Card.Title>
                  {`${property.description}`}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col lg={4}>
          <Card style={{ marginTop: 37 }}>
            <Card.Body>Código: {`${property.code}`}</Card.Body>
          </Card>
          <Card className="contact">
            <div className="header-contact">
              <span>Contacta al Agente encargado</span>
              <img
                className="img-circule"
                src={`${
                  userState && userState.profile_photo?.url
                    ? userState.profile_photo.url
                    : userdefult
                }`}
              />
            </div>
            <div className="body-contact">
              <span>
                {userState && userState.company_name ? (
                  <p>
                    {`${
                      userState && userState.company_name
                        ? userState.company_name
                        : ''
                    }`}
                  </p>
                ) : (
                  <p>{`${
                    userState && userState.first_name
                      ? userState.first_name
                      : ''
                  } ${
                    userState && userState.last_name ? userState.last_name : ''
                  }`}</p>
                )}
              </span>

              {/* <div>
                {showForm &&
                  getViewForm(
                    validated,
                    setName,
                    setEmail,
                    setPhone,
                    onHandlerBeContact,
                    formRef
                  )}
              </div> */}
              <div>
                {!showForm &&
                  getViewMessage(message, onHandlerReturn, property.user.phone)}
              </div>
            </div>
            <div className="footer-contact">
              <button type="button" onClick={onHandlerClikPhone}>
                Llamar
              </button>
            </div>
            <div className="footer-contact">
              <button type="button" onClick={onHandlerClikWhatsApp}>
                Contactar por Whatsapp
              </button>
            </div>
            {/* <div className="footer-company-contact">
              <span>Conoce a Dinamica Agencia Inmobiliaria</span>
            </div> */}
            <div>
              {userState && userState.hubspot_id !== '' && (
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=${userState.hubspot_id}`}
                  className="react-player"
                  width="100%"
                  height="300px"
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

Property.propTypes = {
  user: object,
  propertyDetail: object,
  actions: any.isRequired,
  generalLoading: bool.isRequired,
};

Property.defaultProps = {
  propertyDetail: null,
  user: {},
};

const mapStateToProps = (state) => {
  const { generalLoading } = state.movement;
  const {
    properties: { propertyDetail },
    user: { user },
  } = state || [];

  return {
    propertyDetail,
    user,
    generalLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
