import { func } from 'prop-types';
import React from 'react';
// import { Row, Col } from 'react-flexbox-grid';
import { Button } from 'react-bootstrap';
import './property.scss';

const FixedContainer = ({ onHandlerClikPhone, onHandlerClikWhatsApp }) => {
  return (
    <div className="container-float container-fluid">
      <div className="col-xs-6">
        <Button
          className="button-call btn full-width"
          onClick={onHandlerClikPhone}
        >
          Llamar
        </Button>
      </div>
      <div className="col-xs-6">
        <Button
          className="button-whatsapp btn full-width"
          onClick={onHandlerClikWhatsApp}
        >
          WhatsApp
        </Button>
      </div>
      {/* <Button className="button-contact btn full-width" onClick={executeScroll}>
        Quiero ser contactado
      </Button> */}
    </div>
  );
};

FixedContainer.propTypes = {
  onHandlerClikPhone: func.isRequired,
  onHandlerClikWhatsApp: func.isRequired,
};

FixedContainer.defaultProps = {};
export default FixedContainer;
