import { combineReducers } from 'redux';

import user from './user.reducer';
import properties from './property.reducer';
import movement from './movement.reducer';

const rootReducer = combineReducers({
  user,
  movement,
  properties,
});

export default rootReducer;
