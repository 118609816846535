/* eslint-disable camelcase */
import React from 'react';
import { bool, object } from 'prop-types';
import navImg from '../../assets/image/navbar.svg';
import userdefult from '../../assets/image/lobbie_logo.svg';

import './header.scss';

const Header = ({
  user: { company_name, first_name, last_name, profile_photo },
  color,
  funnel,
}) => {
  console.log(profile_photo?.url);
  const image = !color ? profile_photo?.url : userdefult;

  const headerName = company_name
    ? `${company_name || ''}`
    : `${first_name || ''} ${last_name || ''}`;

  return (
    <header className={funnel ? 'header funnel-header' : 'header'}>
      <img src={navImg} className="background" alt="background" />
      <div
        className="header-container"
        style={!color ? { background: '#128C7E' } : null}
      >
        <div className="info-user-header">
          <img src={image} className="user-logo" alt="user logo" />
          <div>
            <div className="user-company">
              <p>{funnel ? headerName : 'Lobbie App'}</p>
            </div>
            {funnel ? (
              <div className="user-name">en línea</div>
            ) : (
              <div className="user-name">
                {company_name ? (
                  <p>{`${company_name || ''}`}</p>
                ) : (
                  <p>{`${first_name || ''} ${last_name || ''}`}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  user: object.isRequired,
  color: bool,
  funnel: bool,
};

Header.defaultProps = {
  color: true,
  funnel: false,
};

export default Header;
