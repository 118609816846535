/* eslint-disable react/button-has-type */
import { func, number } from 'prop-types';
import React from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';

import './tableFooter.scss';

const TableFooter = ({
  propertiesPerPage,
  totalProperties,
  paginate,
  currentPage,
}) => {
  return (
    <div className="footer-table">
      {totalProperties > 0 && (
        <PaginationControl
          page={currentPage}
          between={3}
          total={totalProperties}
          limit={propertiesPerPage}
          changePage={(page) => {
            paginate(page, true);
          }}
          ellipsis={1}
        />
      )}
    </div>
  );
};

TableFooter.propTypes = {
  propertiesPerPage: number,
  totalProperties: number.isRequired,
  paginate: func.isRequired,
  currentPage: number.isRequired,
};

TableFooter.defaultProps = {
  propertiesPerPage: 10,
};

export default TableFooter;
