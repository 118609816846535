import * as userService from '../services/user.service';
import {
  SET_USERDATA,
  GET_USERDATA,
  SET_DEPARTMENT_DATA,
  SET_CITIES_DATA,
  GENERAL_ERROR,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
} from '../action-types';

const generalError = (error) => {
  return { type: GENERAL_ERROR, error };
};

const showLoading = () => {
  return { type: SHOW_GENERAL_LOADING };
};
const hideLoading = () => {
  return { type: HIDE_GENERAL_LOADING };
};

export const closeError = () => {
  return (dispatch) => {
    dispatch(generalError(null));
  };
};

export const setUser = ({ user }) => {
  function success(newUser) {
    return { type: SET_USERDATA, user: newUser };
  }
  localStorage.setItem('user', JSON.stringify(user));
  return (dispatch) => {
    dispatch(success(user));
  };
};

export const getUserStorage = () => {
  function success(user) {
    return { type: GET_USERDATA, user };
  }
  const user = JSON.parse(localStorage.getItem('user'));
  return (dispatch) => {
    dispatch(success(user));
  };
};

export const getUser = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.getUser({ id }).then(
      (body) => {
        const { data } = body;
        dispatch(setUser(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const setDepartments = (departments) => {
  function success(newDepartments) {
    return { type: SET_DEPARTMENT_DATA, departments: newDepartments };
  }
  return (dispatch) => {
    dispatch(success(departments));
  };
};

export const getDepartment = () => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.getDepartment().then(
      (body) => {
        const { data } = body;
        dispatch(setDepartments(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};

export const setCities = (cities) => {
  function success(newCities) {
    return { type: SET_CITIES_DATA, cities: newCities };
  }
  return (dispatch) => {
    dispatch(success(cities));
  };
};

export const getCities = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    userService.getCities(id).then(
      (body) => {
        const { data } = body;
        dispatch(setCities(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};
