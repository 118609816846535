export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && response.ok && JSON.parse(text);
    if (!response.ok) {
      const messageJson = JSON.parse(text);
      const errorArray = {
        status: response.status,
        text: (data && data.data) || messageJson.data,
      };
      const error = errorArray;
      return Promise.reject(error);
    }

    return data;
  });
}
