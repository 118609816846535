/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import { array, func } from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Row, Col } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';

import { numFormat } from '../../helpers/input/format';

import './property.scss';

const PropertyList = ({ propertyList, goDetailProperty, addToRefs }) => {
  const { t } = useTranslation();
  const eventClick = (id) => {
    goDetailProperty(id);
  };
  return propertyList.map(
    ({
      id,
      price,
      photos,
      area,
      bedrooms,
      parking,
      neighborhood_name,
      type,
      city,
    }) => (
      <div ref={addToRefs} key={id} id={id}>
        <Card
          className="list-property show-on-mobile"
          onClick={() => eventClick(id)}
        >
          <div className="container-img">
            <img src={photos?.length < 0 ? null : photos[0]?.file.url} />
            <div className="container-text-img">
              <p>{`$ ${numFormat(price)}`}</p>
              <p>{area} m2</p>
              <p>
                {`${bedrooms}`} {`${t('propertyList.room')}`}
              </p>
              <p>
                {`${parking}`} {`${t('propertyList.parking')}`}
              </p>
            </div>
          </div>
          <div className="container-description">
            <p>{`${t(`propertyList.${type.replace(/ /g, '')}`)}`}</p>
            <p>{`${neighborhood_name}`}</p>
            <p>{city}</p>
          </div>
        </Card>
        <Row className="property hide-on-mobile" onClick={() => eventClick(id)}>
          <img
            className="property"
            src={photos?.length < 0 ? null : photos[0]?.file.url}
          />
          <Col xs className="detail-container">
            <div>
              <p>{`$ ${numFormat(price)}`}</p>
            </div>
            <div>
              <p>{area} m2</p>
            </div>
            <div>
              <p>
                {`${bedrooms}`} {`${t('propertyList.room')}`}
              </p>
            </div>
            <div>
              <p>
                {`${parking}`} {`${t('propertyList.parking')}`}
              </p>
            </div>
          </Col>

          <Col xs>
            <div className="detail-container-title">
              <p>{`${t(`propertyList.${type.replace(/ /g, '')}`)}`}</p>
            </div>
            <div className="detail-container-title">
              <p>{`${neighborhood_name}`}</p>
            </div>
            <div className="detail-container">
              <p>{`${city}`}</p>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
};

PropertyList.propTypes = {
  propertyList: array.isRequired,
  goDetailProperty: func.isRequired,
  addToRefs: func.isRequired,
};

PropertyList.defaultProps = {
  totalInvested: 0,
};

export default PropertyList;
