export const SET_USERDATA = 'SET_USERDATA';
export const GET_USERDATA = 'GET_USERDATA';
export const SET_DEPARTMENT_DATA = 'SET_DEPARTMENT_DATA';
export const GET_DEPARTMENT_DATA = 'GET_DEPARTMENT_DATA';
export const SET_CITIES_DATA = 'SET_CITIES_DATA';
export const GET_CITIES_DATA = 'GET_CITIES_DATA';
export const GET_PROPERTY_DATA = 'GET_PROPERTY_DATA';
export const SET_PROPERTY_DETAIL_DATA = 'SET_PROPERTY_DETAIL_DATA';
export const SET_PROPERTY_DATA = 'SET_PROPERTY_DATA';
export const SET_DATA_SIZE_DATA = 'SET_DATA_SIZE_DATA';
export const RESET_ALL = 'RESET_ALL';

export const GENERAL_ERROR = 'GENERAL_ERROR';
export const SHOW_GENERAL_LOADING = 'SHOW_GENERAL_LOADING';
export const HIDE_GENERAL_LOADING = 'HIDE_GENERAL_LOADING';

export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_UPDATED_USER = 'SET_UPDATED_USER';
export const SET_DELETED_USER = 'SET_DELETED_USER';
export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_INVESTMENT_CLOSINGS_DATA = 'SET_INVESTMENT_CLOSINGS_DATA';
export const SET_INVESTMENT_CLOSING_DATA = 'SET_INVESTMENT_CLOSING_DATA';
export const SET_CLOSING_SUMMARIES_DATA = 'SET_CLOSING_SUMMARIES_DATA';
export const CLOSE_INVESTMENT_CLOSING = 'CLOSE_INVESTMENT_CLOSING';
export const SET_INVEST_INVESTMENT_CLOSING = 'SET_INVEST_INVESTMENT_CLOSING';
export const CLEAR_INVESTMENT_CLOSING = 'CLEAR_INVESTMENT_CLOSING';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_NEW_COMPANY = 'SET_NEW_COMPANY';
export const SET_UPDATED_COMPANY = 'SET_UPDATED_COMPANY';
export const SET_DELETED_COMPANY = 'SET_DELETED_COMPANY';

export const SET_EMPLOYEE_SUMMARIES_DATA = 'SET_EMPLOYEE_SUMMARIES_DATA';
export const SET_EMPLOYEE_DATA = 'SET_EMPLOYEE_DATA';
export const SET_EMPLOYEE_INVESTMENT_DATA = 'SET_EMPLOYEE_INVESTMENT_DATA';
export const SET_EMPLOYEE_INVESTMENT_ACTIVE_DATA =
  'SET_EMPLOYEE_INVESTMENT_ACTIVE_DATA';
export const RESET_EMPLOYEE_INVESTMENT_DATA = 'RESET_EMPLOYEE_INVESTMENT_DATA';

export const SET_CRYTOS_DATA = 'SET_CRYTOS_DATA';

export const LOGOUT = 'LOGOUT';
