/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingLabel, Form } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import './filter.scss';

const filter = ({
  departments,
  cities,
  handleDepartmentChange,
  handleCityChange,
  showCities,
  showFilterSector,
  handleTypeProperty,
  handleClickSearch,
  typeProperty,
  businessKind,
  departmentId,
  cityId,
  description,
  maxPrice,
  minPrice,
  maxArea,
  minArea,
  setMaxPrice,
  setMinPrice,
  setMaxArea,
  setMinArea,
  setCheck1,
  setCheck2,
  setCheck3,
  setCheck4,
  setCheck5,
  setDescription,
  handleBtnClean,
  checkList,
  handleBusinessType,
}) => {
  const [validatedMax, setValidatedMax] = useState(false);
  const [validatedMin, setValidatedMin] = useState(false);
  const { t } = useTranslation();
  const validatePriceMax = (event) => {
    const { value } = event.target;

    const valueTemp = parseFloat(value.replace('$', '').replaceAll('.', ''));

    if (valueTemp < 10000000) {
      setValidatedMax(true);
    } else {
      setValidatedMax(false);
    }
    if (!Number.isNaN(valueTemp)) {
      setMaxPrice(valueTemp);
    }
  };

  const validatePriceMin = (event) => {
    const { value } = event.target;

    const valueTemp = parseFloat(value.replace('$', '').replaceAll('.', ''));
    if (valueTemp < 10000000) {
      setValidatedMin(true);
    } else {
      setValidatedMin(false);
    }

    if (!Number.isNaN(valueTemp)) {
      setMinPrice(valueTemp);
    }
  };

  const handleDescription = ({ target: { value } }) => {
    setDescription(value);
  };

  const handleMaxArea = ({ target: { value } }) => {
    const area = parseFloat(value.replace('m2', ''));
    if (!Number.isNaN(area)) {
      setMaxArea(area);
    }
  };

  const handleMinArea = ({ target: { value } }) => {
    const area = parseFloat(value.replace('m2', ''));
    if (!Number.isNaN(area)) {
      setMinArea(area);
    }
  };
  const handleSubmit = ({ target }) => {
    target.reset();
  };

  return (
    <Form className="filter" onSubmit={handleSubmit}>
      <div className="filter__dropdown">
        <div className="filter__button__clean">
          <span>FILTROS</span>
          <button type="submit" onClick={handleBtnClean}>
            Limpiar
          </button>
        </div>

        <FloatingLabel
          style={{ marginTop: 10 }}
          controlId="floatingSelect"
          label="Tipo inmueble"
        >
          <Form.Select
            onChange={handleTypeProperty}
            size="sm"
            style={{ border: 0, outline: 0 }}
            value={typeProperty}
          >
            <option value="0">{t('select')}</option>
            <option value="Apartaestudio">Apartaestudio</option>
            <option value="Apartamento">Apartamento</option>
            <option value="Bodega">Bodega</option>
            <option value="Cabaña">Cabaña</option>
            <option value="Casa">Casa</option>
            <option value="Casa Campestre">Casa Campestre</option>
            <option value="Casa Lote">Casa Lote</option>
            <option value="Consultorio">Consultorio</option>
            <option value="Edificio">Edificio</option>
            <option value="Finca">Finca</option>
            <option value="Habitación">Habitación</option>
            <option value="Local">Local</option>
            <option value="Lote">Lote</option>
            <option value="Oficina">Oficina</option>
            <option value="Otros">Otros</option>
          </Form.Select>
        </FloatingLabel>

        <FloatingLabel
          style={{ marginTop: 10 }}
          controlId="floatingSelect"
          label="Tipo Negocio"
        >
          <Form.Select
            size="sm"
            style={{ border: 0, outline: 0 }}
            onChange={handleBusinessType}
            value={businessKind}
          >
            <option value="0">{t('select')}</option>
            <option value="purchase">Comprar</option>
            <option value="rent">Rentar</option>
          </Form.Select>
        </FloatingLabel>

        <FloatingLabel
          style={{ marginTop: 15 }}
          controlId="floatingSelect"
          label="Departamento"
        >
          <Form.Select
            size="sm"
            onChange={handleDepartmentChange}
            style={{ border: 0, outline: 0 }}
            aria-label="Floating label select example"
            value={departmentId}
          >
            <option value="0">{t('select')}</option>
            {departments &&
              departments.map((department) => (
                <option
                  key={department.id}
                  value={`${department.id}`}
                >{`${department.name}`}</option>
              ))}
          </Form.Select>
        </FloatingLabel>
        {showCities && (
          <FloatingLabel
            style={{ marginTop: 15 }}
            controlId="floatingSelect"
            label="Ciudad"
          >
            <Form.Select
              size="sm"
              onChange={handleCityChange}
              style={{ border: 0, outline: 0 }}
              aria-label="Floating label select example"
              value={cityId}
            >
              <option value="0">{t('select')}</option>
              {cities &&
                cities.map((city) => (
                  <option value={`${city.id}`}>{`${city.name}`}</option>
                ))}
            </Form.Select>
          </FloatingLabel>
        )}
        {showFilterSector && (
          <FloatingLabel
            controlId="inputSector"
            label="Barrio o sector"
            className="mb-3"
            style={{ marginTop: 15 }}
          >
            <Form.Control
              style={{ border: 0, outline: 0 }}
              placeholder="Barrio o sector"
              onChange={handleDescription}
              value={description}
            />
          </FloatingLabel>
        )}

        <FloatingLabel
          controlId="inputPriceMin"
          label="Precio Mínimo"
          className="mb-3"
          style={{ marginTop: 15 }}
        >
          <Form.Control
            as={CurrencyFormat}
            isInvalid={validatedMin}
            onChange={validatePriceMin}
            thousandSeparator="."
            decimalSeparator=","
            prefix="$"
            size="sm"
            style={{ border: 0, outline: 0 }}
            placeholder="precio mínimo"
            value={minPrice}
          />

          <Form.Control.Feedback type="invalid">
            El Precio debe ser mayor a $10.000.000
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          controlId="inputPriceMax"
          label="Precio Maximo"
          className="mb-3"
        >
          <Form.Control
            size="sm"
            as={CurrencyFormat}
            isInvalid={validatedMax}
            onChange={validatePriceMax}
            style={{ border: 0, outline: 0 }}
            thousandSeparator="."
            prefix="$"
            decimalSeparator=","
            value={maxPrice}
            placeholder="precio maximo"
          />
          <Form.Control.Feedback type="invalid">
            El Precio debe ser mayor a $10.000.000
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Área Mínima"
          className="mb-3"
        >
          <Form.Control
            size="sm"
            style={{ border: 0, outline: 0 }}
            as={CurrencyFormat}
            onChange={handleMinArea}
            thousandSeparator="."
            decimalSeparator=","
            suffix="m2"
            type="text"
            placeholder="area mínima"
            value={minArea}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Área Máxima"
          className="mb-3"
        >
          <Form.Control
            size="sm"
            style={{ border: 0, outline: 0 }}
            as={CurrencyFormat}
            onChange={handleMaxArea}
            thousandSeparator="."
            decimalSeparator=","
            suffix="m2"
            type="text"
            placeholder="área maxima"
            value={maxArea}
          />
        </FloatingLabel>

        <div className="filter__subtitle">Habitaciones</div>

        <Form.Check
          checked={checkList.check01}
          inline
          label="1"
          name="group1"
          type="checkbox"
          id="checkbox-1"
          onChange={({ target: { checked } }) => setCheck1(checked)}
        />
        <Form.Check
          inline
          checked={checkList.check02}
          label="2"
          name="group1"
          type="checkbox"
          id="checkbox-2"
          onChange={({ target: { checked } }) => setCheck2(checked)}
        />
        <Form.Check
          checked={checkList.check03}
          inline
          label="3"
          name="group1"
          type="checkbox"
          id="checkbox-3"
          onChange={({ target: { checked } }) => setCheck3(checked)}
        />
        <Form.Check
          checked={checkList.check04}
          inline
          label="4"
          name="group1"
          type="checkbox"
          id="checkbox-4"
          onChange={({ target: { checked } }) => setCheck4(checked)}
        />
        <Form.Check
          checked={checkList.check05}
          inline
          label="5"
          name="group1"
          type="checkbox"
          onChange={({ target: { checked } }) => setCheck5(checked)}
        />

        <button
          style={{ backgroundColor: '#5d9cec', border: 'none' }}
          type="button"
          className="filter__button__search"
          onClick={handleClickSearch}
        >
          Buscar
        </button>
      </div>
    </Form>
  );
};

export default filter;
