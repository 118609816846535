import React from 'react';

const NotFound = () => {
  document.body.style = 'background: white;';
  return (
    <div>
      <h1>404 - Not Found!</h1>
      <h3>Enter a userId</h3>
    </div>
  );
};

NotFound.propTypes = {};

NotFound.defaultProps = {};

export default NotFound;
