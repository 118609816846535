import * as propertyService from '../services/property.service';
import {
  // GET_PROPERTY_DATA,
  SET_PROPERTY_DATA,
  SET_DATA_SIZE_DATA,
  GENERAL_ERROR,
  SHOW_GENERAL_LOADING,
  HIDE_GENERAL_LOADING,
  SET_PROPERTY_DETAIL_DATA,
} from '../action-types';

const generalError = (error) => {
  return { type: GENERAL_ERROR, error };
};

const showLoading = () => {
  return { type: SHOW_GENERAL_LOADING };
};
const hideLoading = () => {
  return { type: HIDE_GENERAL_LOADING };
};

const setProperties = (properties) => {
  return { type: SET_PROPERTY_DATA, properties };
};
const setDataSize = (dataSize) => {
  return { type: SET_DATA_SIZE_DATA, dataSize };
};
const setPropertiesDetail = (propertyDetail) => {
  return { type: SET_PROPERTY_DETAIL_DATA, propertyDetail };
};

export const getProperties = (
  id,
  { typeProperty, departmentId, cityId, query, businessKind, page, userOwner }
) => {
  return (dispatch) => {
    dispatch(showLoading());
    propertyService
      .getProperties({
        id,
        typeProperty,
        departmentId,
        cityId,
        query,
        businessKind,
        page,
        userOwner,
      })
      .then(
        (body) => {
          const { data, data_size: dataSize } = body;
          dispatch(setProperties(data));
          dispatch(setDataSize(dataSize));
          dispatch(hideLoading());
        },
        (error) => {
          dispatch(generalError(error));
          dispatch(hideLoading(error));
        }
      );
  };
};

export const getPropertyByid = (id) => {
  return (dispatch) => {
    dispatch(showLoading());
    propertyService.getPropertyByid({ id }).then(
      (body) => {
        const { data } = body;
        dispatch(setPropertiesDetail(data));
        dispatch(hideLoading());
      },
      (error) => {
        dispatch(generalError(error));
        dispatch(hideLoading(error));
      }
    );
  };
};
