import { handleResponse } from '../../helpers';

export const getUser = ({ id }) => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/user/${id}`,
    requestOptions
  ).then(handleResponse);
};

export const getDepartment = () => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/departments`,
    requestOptions
  ).then(handleResponse);
};

export const getCities = (id) => {
  const requestOptions = {
    method: 'GET',
  };
  return fetch(
    `${process.env.REACT_APP_API_URL}/cities/${id}`,
    requestOptions
  ).then(handleResponse);
};
