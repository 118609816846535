// Format
const numOnly = (value) => value && `${value}`.replace(/[^0-9.]/g, '');

const numOnlyToDots = (value) => value && `${value}`.replace(/[^0-9]/g, '');

const numFormat = (value, maximumFractionDigits = 2) =>
  value
    ? parseFloat(numOnly(value)).toLocaleString('en', {
        maximumFractionDigits,
      })
    : 0;

export { numOnly, numFormat, numOnlyToDots };
