import {
  RESET_ALL,
  SET_DATA_SIZE_DATA,
  SET_PROPERTY_DATA,
  GET_USERDATA,
  SET_PROPERTY_DETAIL_DATA,
} from '../action-types';

const initialState = {
  propertyDetail: null,
};

// eslint-disable-next-line default-param-last
const properties = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROPERTY_DATA: {
      return {
        ...state,
        properties: action.properties,
      };
    }
    case SET_DATA_SIZE_DATA: {
      return {
        ...state,
        dataSize: action.dataSize,
      };
    }
    case SET_PROPERTY_DETAIL_DATA: {
      return {
        ...state,
        propertyDetail: action.propertyDetail,
      };
    }
    case GET_USERDATA: {
      return {
        ...state,
        user: action.user,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default properties;
