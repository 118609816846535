/* eslint-disable jsx-a11y/label-has-associated-control */
import { array, bool, func, object, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FloatingLabel, Form, Card, Button } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { Row, Col } from 'react-flexbox-grid';
import './filter.scss';

// const showWithButton = () => <div>sss</div>;

const FilterButton = ({
  onClickShowFilter,
  showFilter,
  departments,
  handleDepartmentChange,
  handleCityChange,
  showCities,
  cities,
  showFilterSector,
  handleTypeProperty,
  handleClickSearch,
  businessKind,
  typeProperty,
  departmentId,
  cityId,
  description,
  maxPrice,
  minPrice,
  maxArea,
  minArea,
  setMaxPrice,
  setMinPrice,
  setMaxArea,
  setMinArea,
  setCheck1,
  setCheck2,
  setCheck3,
  setCheck4,
  setCheck5,
  setDescription,
  checkList,
  handleBtnClean,
  handleBusinessType,
}) => {
  const { t } = useTranslation();
  const [validatedMax, setValidatedMax] = useState(false);
  const [validatedMin, setValidatedMin] = useState(false);

  const validatePriceMax = (event) => {
    const { value } = event.target;

    const valueTemp = parseFloat(value.replace('$', '').replaceAll('.', ''));

    if (valueTemp < 10000000) {
      setValidatedMax(true);
    } else {
      setValidatedMax(false);
    }
    if (!string.isNaN(valueTemp)) {
      setMaxPrice(valueTemp);
    }
  };

  const validatePriceMin = (event) => {
    const { value } = event.target;

    const valueTemp = parseFloat(value.replace('$', '').replaceAll('.', ''));
    if (valueTemp < 10000000) {
      setValidatedMin(true);
    } else {
      setValidatedMin(false);
    }

    if (!string.isNaN(valueTemp)) {
      setMinPrice(valueTemp);
    }
  };

  const handleDescription = ({ target: { value } }) => {
    setDescription(value);
  };

  const handleMaxArea = ({ target: { value } }) => {
    const area = parseFloat(value.replace('m2', ''));
    if (!string.isNaN(area)) {
      setMaxArea(area);
    }
  };

  const handleMinArea = ({ target: { value } }) => {
    const area = parseFloat(value.replace('m2', ''));
    if (!string.isNaN(area)) {
      setMinArea(area);
    }
  };
  const handleSubmit = ({ target }) => {
    target.reset();
  };

  return (
    <Row lg="true">
      <Col style={{ textAlign: 'center', paddingBottom: 30 }}>
        {showFilter ? (
          <Row>
            <Col lg={6} xs={6} style={{ textAlign: 'left' }}>
              <Card.Subtitle onClick={onClickShowFilter}>Atras</Card.Subtitle>
            </Col>
            <Col lg={6} xs={6} style={{ textAlign: 'right' }}>
              <Card.Subtitle onClick={handleClickSearch}>Buscar</Card.Subtitle>
            </Col>
          </Row>
        ) : (
          <Button
            style={{ backgroundColor: '#5d9cec', border: 'none' }}
            type="button"
            onClick={onClickShowFilter}
          >
            Filtrar
          </Button>
        )}

        {showFilter && (
          <Col>
            <Card style={{ marginTop: 20, height: 'auto', marginBottom: 80 }}>
              <Form style={{ paddingBottom: 16 }} onSubmit={handleSubmit}>
                <FloatingLabel
                  style={{ marginTop: 10 }}
                  controlId="floatingSelect"
                  label="Tipo inmueble"
                >
                  <Form.Select
                    onChange={handleTypeProperty}
                    size="sm"
                    style={{ border: 0, outline: 0 }}
                    value={typeProperty}
                  >
                    <option value="0">{t('select')}</option>
                    <option value="Apartaestudio">Apartaestudio</option>
                    <option value="Apartamento">Apartamento</option>
                    <option value="Bodega">Bodega</option>
                    <option value="Cabaña">Cabaña</option>
                    <option value="Casa">Casa</option>
                    <option value="Casa Campestre">Casa Campestre</option>
                    <option value="Casa Lote">Casa Lote</option>
                    <option value="Consultorio">Consultorio</option>
                    <option value="Edificio">Edificio</option>
                    <option value="Finca">Finca</option>
                    <option value="Habitación">Habitación</option>
                    <option value="Local">Local</option>
                    <option value="Lote">Lote</option>
                    <option value="Oficina">Oficina</option>
                    <option value="Otros">Otros</option>
                  </Form.Select>
                </FloatingLabel>

                <FloatingLabel
                  style={{ marginTop: 10 }}
                  controlId="floatingSelect"
                  label="Tipo Negocio"
                >
                  <Form.Select
                    size="sm"
                    style={{ border: 0, outline: 0 }}
                    onChange={handleBusinessType}
                    value={businessKind}
                  >
                    <option value="0">{t('select')}</option>
                    <option value="purchase">Comprar</option>
                    <option value="rent">Rentar</option>
                  </Form.Select>
                </FloatingLabel>

                <FloatingLabel
                  style={{ marginTop: 15 }}
                  controlId="floatingSelect"
                  label="Departamento"
                >
                  <Form.Select
                    size="sm"
                    style={{ border: 0, outline: 0 }}
                    onChange={handleDepartmentChange}
                    aria-label="Floating label select example"
                    value={departmentId}
                  >
                    <option value="0">{t('select')}</option>
                    {departments &&
                      departments.map((department) => (
                        <option
                          value={`${department.id}`}
                        >{`${department.name}`}</option>
                      ))}
                  </Form.Select>
                </FloatingLabel>

                {showCities && (
                  <FloatingLabel
                    style={{ marginTop: 15 }}
                    controlId="floatingSelect"
                    label="Ciudad"
                  >
                    <Form.Select
                      size="sm"
                      onChange={handleCityChange}
                      style={{ border: 0, outline: 0 }}
                      aria-label="Floating label select example"
                      value={cityId}
                    >
                      <option value="0">{t('select')}</option>
                      {cities &&
                        cities.map((city) => (
                          <option value={`${city.id}`}>{`${city.name}`}</option>
                        ))}
                    </Form.Select>
                  </FloatingLabel>
                )}
                {showFilterSector && (
                  <FloatingLabel
                    controlId="inputSector"
                    label="Barrio o sector"
                    className="mb-3"
                    style={{ marginTop: 15 }}
                  >
                    <Form.Control
                      style={{ border: 0, outline: 0 }}
                      onChange={handleDescription}
                      value={description}
                      placeholder="Barrio o sector"
                    />
                  </FloatingLabel>
                )}

                <FloatingLabel
                  controlId="inputPriceMin"
                  label="Precio Mínimo"
                  className="mb-3"
                  style={{ marginTop: 15 }}
                >
                  <Form.Control
                    as={CurrencyFormat}
                    isInvalid={validatedMin}
                    onChange={validatePriceMin}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                    size="sm"
                    style={{ border: 0, outline: 0 }}
                    placeholder="precio mínimo"
                    value={minPrice}
                  />

                  <Form.Control.Feedback type="invalid">
                    El Precio debe ser mayor a $10.000.000
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  controlId="inputPriceMax"
                  label="Precio Maximo"
                  className="mb-3"
                >
                  <Form.Control
                    size="sm"
                    as={CurrencyFormat}
                    isInvalid={validatedMax}
                    onChange={validatePriceMax}
                    style={{ border: 0, outline: 0 }}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="$"
                    placeholder="precio maximo"
                    value={maxPrice}
                  />
                  <Form.Control.Feedback type="invalid">
                    El Precio debe ser mayor a $10.000.000
                  </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel
                  controlId="floatingInput"
                  label="Área Mínima"
                  className="mb-3"
                >
                  <Form.Control
                    size="sm"
                    style={{ border: 0, outline: 0 }}
                    as={CurrencyFormat}
                    onChange={handleMinArea}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="m2"
                    type="text"
                    placeholder="area mínima"
                    value={minArea}
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="floatingInput"
                  label="Área Máxima"
                  className="mb-3"
                >
                  <Form.Control
                    size="sm"
                    style={{ border: 0, outline: 0 }}
                    as={CurrencyFormat}
                    onChange={handleMaxArea}
                    thousandSeparator="."
                    decimalSeparator=","
                    suffix="m2"
                    type="text"
                    placeholder="área maxima"
                    value={maxArea}
                  />
                </FloatingLabel>

                <div className="filter__subtitle">Habitaciones</div>

                <Form.Check
                  checked={checkList.check01}
                  inline
                  label="1"
                  name="group1"
                  type="checkbox"
                  id="checkbox-1"
                  onChange={({ target: { checked } }) => setCheck1(checked)}
                />
                <Form.Check
                  inline
                  checked={checkList.check02}
                  label="2"
                  name="group1"
                  type="checkbox"
                  id="checkbox-2"
                  onChange={({ target: { checked } }) => setCheck2(checked)}
                />
                <Form.Check
                  checked={checkList.check03}
                  inline
                  label="3"
                  name="group1"
                  type="checkbox"
                  id="checkbox-3"
                  onChange={({ target: { checked } }) => setCheck3(checked)}
                />
                <Form.Check
                  checked={checkList.check04}
                  inline
                  label="4"
                  name="group1"
                  type="checkbox"
                  id="checkbox-4"
                  onChange={({ target: { checked } }) => setCheck4(checked)}
                />
                <Form.Check
                  checked={checkList.check05}
                  inline
                  label="5"
                  name="group1"
                  type="checkbox"
                  onChange={({ target: { checked } }) => setCheck5(checked)}
                />
                <Button
                  style={{
                    backgroundColor: '#5d9cec',
                    border: 'none',
                    marginTop: '20px',
                  }}
                  type="button"
                  className="filter__button__search"
                  onClick={handleClickSearch}
                >
                  Buscar
                </Button>
                <div className="filter_button_clean">
                  <div className="filter_container_button_clean">
                    <Button
                      style={{ backgroundColor: '#F24182', border: 'none' }}
                      type="submit"
                      onClick={handleBtnClean}
                    >
                      Limpiar
                    </Button>
                  </div>
                </div>
              </Form>
            </Card>
          </Col>
        )}
      </Col>
    </Row>
  );
};

FilterButton.propTypes = {
  onClickShowFilter: func.isRequired,
  showFilter: bool.isRequired,
  departments: array.isRequired,
  handleDepartmentChange: func.isRequired,
  handleCityChange: func.isRequired,
  showCities: bool.isRequired,
  showFilterSector: bool.isRequired,
  cities: array.isRequired,
  handleTypeProperty: func.isRequired,
  handleClickSearch: func.isRequired,
  typeProperty: string.isRequired,
  businessKind: string.isRequired,
  departmentId: string.isRequired,
  cityId: string.isRequired,
  description: string.isRequired,
  maxPrice: string.isRequired,
  minPrice: string.isRequired,
  maxArea: string.isRequired,
  minArea: string.isRequired,
  setDescription: func.isRequired,
  setMaxPrice: func.isRequired,
  setMinPrice: func.isRequired,
  setMaxArea: func.isRequired,
  setMinArea: func.isRequired,
  setCheck1: func.isRequired,
  setCheck2: func.isRequired,
  setCheck3: func.isRequired,
  setCheck4: func.isRequired,
  setCheck5: func.isRequired,
  checkList: object.isRequired,
  handleBtnClean: func.isRequired,
  handleBusinessType: func.isRequired,
};

FilterButton.defaultProps = {};

export default FilterButton;
