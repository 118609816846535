/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-use-before-define */
import { useLocation } from 'react-router-dom';

const param = {
  typeProperty: '0',
  cityId: '0',
  departmentId: '0',
  businessKind: '0',

  minPrice: '0',
  maxPrice: '0',
  minArea: '0',
  maxArea: '0',
  description: '',

  check1: false,
  check2: false,
  check3: false,
  check4: false,
  check5: false,

  page: 1,
};

export const params = (body, searchParams, setSearchParams) => {
  /* For item of query */
  for (const bodyKey in body) {
    const value = body[bodyKey];
    /* Processing data */
    switch (bodyKey) {
      case 'query':
        /* Exit data modifed */
        getValueParams(value['bedrooms'], searchParams, setSearchParams);
        getValueParams(value, searchParams, setSearchParams);
        break;
      case 'page':
        setParams(bodyKey, value + 1, searchParams, setSearchParams);
        break;
      default:
        /* No found key, continue process normal */
        if (bodyKey !== 'userOwner') {
          setParams(bodyKey, value, searchParams, setSearchParams);
        }
        break;
    }
  }
};

export const getValueParams = (body, searchParams, setSearchParams) => {
  for (const key in body) {
    const value = body[key];
    if (key !== 'bedrooms') {
      setParams(key, value, searchParams, setSearchParams);
    }
  }
};

export const setParams = (key, value, searchParams, setSearchParams) => {
  if (value !== undefined && value !== '0' && value !== false && value !== '') {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }
};

export const getParams = () => {
  const location = useLocation();
  const paramsValue = new URLSearchParams(location.search);
  for (const key in param) {
    const value = paramsValue.get(`${key}`);
    if (value) {
      param[key] = value;
    }
  }
  return param;
};

export const getQuery = (user) => {
  getParams();
  const query = {
    minPrice: param.minPrice,
    maxPrice: param.maxPrice,
    minArea: param.minArea,
    maxArea: param.maxArea,
    description: param.description,
    bedrooms: {
      check1: param.check1 === 'true',
      check2: param.check2 === 'true',
      check3: param.check3 === 'true',
      check4: param.check4 === 'true',
      check5: param.check5 === 'true',
    },
    smls: user?.smls,
    my_net: user?.my_net,
  };
  return query;
};
