import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-flexbox-grid';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { any, array, number, object } from 'prop-types';
import * as reduxActions from '../../redux/actions';
import { getUserId } from '../../helpers/index';
import Filter from '../../components/filter/filter';
import FilterButton from '../../components/filter/filterButton';
import PropertyList from '../../components/property/propertyList';
import TableFooter from '../../components/tableFooter/index';
import './home.scss';

import { params, getParams, getQuery, setParams } from '../../helpers/params';

const Home = ({ actions, properties, departments, cities, user, dataSize }) => {
  document.body.style = 'background: white;';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const param = getParams();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [propertiesPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(Number(param.page));
  const [showFilter, setShowFilter] = useState(false);
  const [showFilterSector, setShowFilterSector] = useState(false);
  const [showCities, setShowCities] = useState(false);
  const [departmentId, setDepartMentId] = useState(param.departmentId);
  const [cityId, setCityId] = useState(param.cityId);
  const [businessKind, setBusinessKind] = useState(param.businessKind);
  const [typeProperty, setTypeProperty] = useState(param.typeProperty);
  const [description, setDescription] = useState(param.description);
  const [maxPrice, setMaxPrice] = useState(param.maxPrice);
  const [minPrice, setMinPrice] = useState(param.minPrice);
  const [maxArea, setMaxArea] = useState(param.maxArea);
  const [minArea, setMinArea] = useState(param.minArea);
  const [check01, setCheck1] = useState(param.check1 === 'true');
  const [check02, setCheck2] = useState(param.check2 === 'true');
  const [check03, setCheck3] = useState(param.check3 === 'true');
  const [check04, setCheck4] = useState(param.check4 === 'true');
  const [check05, setCheck5] = useState(param.check5 === 'true');
  const [query, setQuery] = useState(getQuery());
  const scrollToRef = () => window.scrollTo(0, 0);
  const location = useLocation();
  const { state } = location;
  const [stateLocation, setStateLocation] = useState(state);
  const arrayRefs = useRef([]);
  arrayRefs.current = [];

  const paginate = (pageNumber, loading) => {
    if (loading) {
      scrollToRef();
    }
    setParams('page', pageNumber, searchParams, setSearchParams);
    setCurrentPage(pageNumber);
    actions.getProperties(getUserId(), {
      typeProperty,
      cityId,
      departmentId,
      query,
      businessKind,
      userOwner: user,
      page: pageNumber - 1,
    });
  };

  useEffect(() => {
    if (window.screen.availWidth > 768) {
      const body = {
        typeProperty,
        cityId,
        departmentId,
        businessKind,
        query,
        page: currentPage - 1,
        userOwner: user,
      };

      actions.getProperties(getUserId(), body);
      params(body, searchParams, setSearchParams);
    }
  }, [departmentId, cityId, typeProperty, query, businessKind, user]);

  useEffect(() => {
    if (window.screen.availWidth <= 768) {
      const body = {
        typeProperty,
        cityId,
        departmentId,
        businessKind,
        query,
        page: currentPage - 1,
        userOwner: user,
      };
      actions.getProperties(getUserId(), body);
      params(body, searchParams, setSearchParams);
    }
  }, [query, user]);

  useEffect(() => {
    if (properties) {
      actions.getDepartment();
    }
    if (departmentId) {
      actions.getCities(departmentId);
    }
    if (cityId !== '0') {
      setShowFilterSector(true);
    }
  }, []);

  useEffect(() => {
    setStateLocation(location.state);
  }, [location]);

  useEffect(() => {
    if (
      arrayRefs.current.length > 0 &&
      stateLocation &&
      stateLocation.propertyId !== null
    ) {
      const ref = arrayRefs.current.find(
        (refElement) => parseInt(refElement.id, 10) === stateLocation.propertyId
      );

      if (!ref) paginate(stateLocation.pageNumber, false);

      if (ref) ref.scrollIntoView();
    }
  }, [stateLocation]);

  const onClickShowFilter = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  const goDetailProperty = (id) => {
    if (typeof id !== 'undefined') {
      const urlProperty = `/${getUserId()}/${id}`;
      navigate(urlProperty, {
        state: {
          showContainer: true,
          propertyId: id,
          pageNumber: currentPage,
        },
      });
    }
  };

  const handleBusinessType = ({ target: { value } }) => {
    setCurrentPage(1);
    setBusinessKind(value);
  };

  const handleDepartmentChange = ({ target: { value } }) => {
    setCurrentPage(1);
    actions.getCities(value);
    if (value === '0') {
      setShowCities(false);
      setShowFilterSector(false);
    }
    setShowCities(true);
    setDepartMentId(value);
  };

  const handleCityChange = ({ target: { value } }) => {
    setCityId(value);
    setCurrentPage(1);
    setShowFilterSector(true);
  };

  const handleTypeProperty = ({ target: { value } }) => {
    setCurrentPage(1);
    setTypeProperty(value);
  };

  const handleClickSearch = () => {
    setCurrentPage(1);
    setQuery({
      minPrice,
      maxPrice,
      minArea,
      maxArea,
      description,
      bedrooms: {
        check1: check01,
        check2: check02,
        check3: check03,
        check4: check04,
        check5: check05,
      },
    });
    setShowFilter(false);
  };

  const handleBtnClean = () => {
    setQuery({
      minPrice: '',
      maxPrice: '',
      minArea: '',
      maxArea: '',
      bedrooms: {
        check1: '',
        check2: '',
        check3: '',
        check4: '',
        check5: '',
      },
    });
    setCheck1(false);
    setCheck2(false);
    setCheck3(false);
    setCheck4(false);
    setCheck5(false);
    setMaxPrice('');
    setMinPrice('');
    setMaxArea('');
    setMinPrice('');
    setDescription('');
  };

  const addToRefs = (el) => {
    if (el && !arrayRefs.current.includes(el)) {
      arrayRefs.current.push(el);
    }
  };

  return (
    <div className="home container-fluid">
      <Row>
        <Col lg={2}>
          <div className="show-on-mobile">
            <FilterButton
              onClickShowFilter={onClickShowFilter}
              showFilter={showFilter}
              departments={departments}
              cities={cities}
              handleDepartmentChange={handleDepartmentChange}
              handleCityChange={handleCityChange}
              showCities={showCities}
              handleTypeProperty={handleTypeProperty}
              handleClickSearch={handleClickSearch}
              typeProperty={typeProperty}
              businessKind={businessKind}
              departmentId={departmentId}
              cityId={cityId}
              description={description}
              maxPrice={maxPrice}
              minPrice={minPrice}
              maxArea={maxArea}
              minArea={minArea}
              setDescription={setDescription}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
              setMaxArea={setMaxArea}
              setMinArea={setMinArea}
              setCheck1={setCheck1}
              setCheck2={setCheck2}
              setCheck3={setCheck3}
              setCheck4={setCheck4}
              setCheck5={setCheck5}
              checkList={{ check01, check02, check03, check04, check05 }}
              handleBtnClean={handleBtnClean}
              handleBusinessType={handleBusinessType}
              showFilterSector={showFilterSector}
            />
          </div>
          <div className="hide-on-mobile">
            <Filter
              departments={departments}
              cities={cities}
              handleDepartmentChange={handleDepartmentChange}
              handleCityChange={handleCityChange}
              showCities={showCities}
              handleTypeProperty={handleTypeProperty}
              handleClickSearch={handleClickSearch}
              typeProperty={typeProperty}
              businessKind={businessKind}
              departmentId={departmentId}
              cityId={cityId}
              description={description}
              maxPrice={maxPrice}
              minPrice={minPrice}
              maxArea={maxArea}
              minArea={minArea}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
              setMaxArea={setMaxArea}
              setMinArea={setMinArea}
              setCheck1={setCheck1}
              setCheck2={setCheck2}
              setCheck3={setCheck3}
              setCheck4={setCheck4}
              setCheck5={setCheck5}
              setDescription={setDescription}
              handleBtnClean={handleBtnClean}
              checkList={{ check01, check02, check03, check04, check05 }}
              handleBusinessType={handleBusinessType}
              showFilterSector={showFilterSector}
            />
          </div>
        </Col>
        {!showFilter && (
          <Col lg>
            <h1>
              {dataSize} {t('home.properties')} Colombia
            </h1>
            <PropertyList
              propertyList={properties}
              goDetailProperty={goDetailProperty}
              addToRefs={addToRefs}
            />
            <TableFooter
              propertiesPerPage={propertiesPerPage}
              totalProperties={dataSize}
              paginate={paginate}
              currentPage={currentPage}
              t={t}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

Home.propTypes = {
  actions: any.isRequired,
  properties: array,
  departments: array,
  cities: array,
  dataSize: number,
  user: object,
};

Home.defaultProps = {
  properties: [],
  departments: [],
  cities: [],
  dataSize: 0,
  user: {},
};
const mapStateToProps = (state) => {
  const {
    properties: { properties, propertyDetail, dataSize },
    user: { user, departments, cities },
  } = state || [];

  return {
    dataSize,
    properties,
    propertyDetail,
    user,
    departments,
    cities,
    state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(reduxActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
