import {
  RESET_ALL,
  SET_USERDATA,
  GET_USERDATA,
  SET_DEPARTMENT_DATA,
  GET_DEPARTMENT_DATA,
  SET_CITIES_DATA,
  GET_CITIES_DATA,
} from '../action-types';

const initialState = {
  user: { smls: false, my_net: false },
  departments: [],
  cities: [],
};

// eslint-disable-next-line default-param-last
const users = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERDATA: {
      return {
        ...state,
        user: action.user,
      };
    }

    case GET_USERDATA: {
      return {
        ...state,
        user: action.user,
      };
    }

    case SET_DEPARTMENT_DATA: {
      return {
        ...state,
        departments: action.departments,
      };
    }

    case GET_DEPARTMENT_DATA: {
      return {
        ...state,
        departments: action.departments,
      };
    }

    case SET_CITIES_DATA: {
      return {
        ...state,
        cities: action.cities,
      };
    }

    case GET_CITIES_DATA: {
      return {
        ...state,
        cities: action.cities,
      };
    }

    case RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};

export default users;
